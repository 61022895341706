<template>
    <div class="is-name">
         <div class="header">
          <van-icon name="arrow-left" class="icon" @click="goBack()" />
          <div class="text">性别</div>
        </div>
        <!-- <van-cell-group class="sex-list">
          <van-cell title="男"  class="sex">   <van-radio class="sex-radio" icon-size=".26rem" checked-color="#EE3A3A" v-model="boy"  name="1" @click="save(2)"></van-radio> </van-cell>
          <van-cell title="女"  class="sex">   <van-radio class="sex-radio" icon-size=".26rem"  checked-color="#EE3A3A" v-model="girl" name="2" @click="save(1)"></van-radio> </van-cell>
        </van-cell-group> -->

       <van-radio-group class="sex-list" v-model="sex">
        <van-cell-group>
          <van-cell title="男" clickable  @click="radio = '2'">
            <template #right-icon>
              <van-radio icon-size=".32rem" @click="save(2)"  name="2" />
            </template>
          </van-cell>
          <van-cell title="女" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio icon-size=".32rem" @click="save(1)"  name="1" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

    </div>
</template>

<script>
export default {
  data(){
    return{
      sex:false,
      activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png',
     
    }
  },
  mounted(){
    this.getUser()
  },
    methods:{
         goBack() {
             this.$router.go(-1)
        },

         getUser(){
          this.$api.my
                    .ownDetailAddress({
                        accessToken: localStorage.getItem("token"),
                        userId: localStorage.getItem("customerId"),
                        storeId:localStorage.storeId,
                    }).then(res=>{
                      if(res.data.user){
                        console.log(res);
                        if( res.data.user.gender == 1)
                        this.sex = '1'
                        if( res.data.user.gender == 2)
                        this.sex = '2'
                       
                      }
                   
                    })
    },
        save(e){
          
       this.$api.my
            .userEdit({
              userId:localStorage.customerId,
              gender: e,
            }).then(res=>{
                if(res.errno === 200){
                    this.$toast('修改成功')
                    this.$router.go(-1)
                }
            })
        this.showSex = false
        }
    }
}
</script>

<style lang='less' scoped>

.is-name {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  
  background: #F6F5FA;
  .header {
    background-color: #ffffff;
    width: 100%;
    height: 0.88rem /* 44/50 */;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    z-index: 99999;
    position: fixed;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    .icon {
      position: fixed;
      left: 0.3rem /* 15/50 */;
      color: #7e7e7e;
      font-size: 0.36rem /* 18/50 */;
      width: 1em;
      height: 1em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
    .text {
      color: #333;
      font-size: 0.36rem /* 18/50 */;
      font-family: "PingFang SC";
      font-weight: 500;
    }
    .save {
      position: fixed;
      right: 0.3rem /* 15/50 */;
      font-size: 0.32rem /* 16/50 */;
      font-family: PingFang SC;
      font-weight: 400;
      color: #194cdd;
    }
  }
}
.sex-list{
  margin-top: 1.24rem;
  font-size: .24rem;
}
.sex{
  position: relative;
  .sex-radio{
    position: absolute;
    top: .1rem;
    right: .2rem;
    
    
  }
}
.van-cell{
font-size: .28rem;
font-family: PingFang SC;
font-weight: 400;
color: #111111;
line-height: .4rem;
}
// /deep/.van-radio__icon .van-icon{
//   width: .26rem;
//   height: .26rem;
// }
/deep/.van-radio__icon--checked .van-icon {
  border-color: @sex_checked_color;
  background-color: @sex_checked_color;
}
</style>